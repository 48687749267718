import {
    ComponentType,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react"
import {
    DynamicAnimationOptions,
    Easing,
    stagger,
    useAnimate,
    useInView,
} from "framer-motion"
import { FrameProps } from "framer"

const MIN_FONT_SIZE = "2rem"
const MAX_FONT_SIZE = "40rem"

export function withAnimateChar(
    Component: ComponentType<FrameProps>
): ComponentType<FrameProps> {
    return (props: FrameProps) => {
        const [scopeRef, animate] = useAnimate()
        const isInView = useInView(scopeRef, {
            amount: 0.5,
        })
        const easeOutCirc: Easing = [0.075, 0.82, 0.165, 1]
        const easeInCirc: Easing = [0.6, 0.04, 0.98, 0.335]
        const settings: DynamicAnimationOptions = {
            delay: stagger(0.1),
        }

        useEffect(() => {
            if (isInView) {
                animate(
                    "span",
                    { y: ["100%", 0] },
                    {
                        ...settings,
                        duration: 2,
                        ease: easeOutCirc,
                        onPlay: () => (scopeRef.current.style.opacity = 1),
                    }
                )
            } else {
                animate(
                    "span",
                    { y: [0, "-100%"] },
                    {
                        ...settings,
                        duration: 1,
                        ease: easeInCirc,
                    }
                )
            }
        }, [isInView])

        useEffect(() => {
            const text = scopeRef.current.querySelector(".framer-text")

            text.style.display = "flex"
            text.style.justifyContent = "center"
            text.style.fontSize = `clamp(${MIN_FONT_SIZE}, 28cqi, ${MAX_FONT_SIZE})`
            text.innerHTML = text.textContent
                .split("")
                .map(
                    (character, index) =>
                        `<span style="display: inline-block">${character}</span>`
                )
                .join("")
        }, [])

        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    opacity: 0,
                }}
                ref={scopeRef}
            />
        )
    }
}

// Add this function for the footer
export function withFooter(
    Component: ComponentType<FrameProps>
): ComponentType<FrameProps> {
    return (props: FrameProps) => (
        <>
            <Component {...props} />
            <footer style={footerStyles}>
                <p>Footer content goes here</p>
            </footer>
        </>
    )
}

// Styles for the footer
const footerStyles: React.CSSProperties = {
    padding: "1em",
    backgroundColor: "#f1f1f1",
    textAlign: "center",
    position: "fixed",
    bottom: 0,
    width: "100%",
}

// Styles for the container
const containerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "1em",
    boxSizing: "border-box",
}

// Styles for the images
const imageStyles: React.CSSProperties = {
    maxWidth: "100%",
    height: "auto",
    marginBottom: "1em",
}

// Main component with responsive layout and image fitting
const MainComponent: React.FC<FrameProps> = (props) => {
    return <div style={containerStyles}>{props.children}</div>
}

// Wrap the MainComponent with HOCs
export const EnhancedComponent = withFooter(withAnimateChar(MainComponent))
